<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <new-provider @add="addProviderSidebar" :isSidebarActive="showNewProviderSidebar"
                  @closeSidebar="showNewProviderSidebar= false"/>

    <new-address :ubigeo="ubigeo" :departments="departments" :provinces="provinces" :districts="districts"
                 :providers="[]"
                 :provider="document.provider"
                 @add="addAddressSidebar" :isSidebarActive="showAddressSidebar"
                 @closeSidebar="showAddressSidebar= false"/>

    <new-contact
        :providers="[]"
        :provider="document.provider"
        @add="addContactSidebar" :isSidebarActive="showContactSidebar"
        @closeSidebar="showContactSidebar= false"/>

    <search-quotes :quotes="quotes" @add="addQuoteSidebar" :isSidebarActive="showQuotesSidebar"
                   @closeSidebar="showQuotesSidebar= false"/>

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nueva orden de compra</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Quotes-->
        <div>
          <vs-button @click="showQuotesSidebar= true" :disabled="progress" class="w-full" color="danger"
                     type="border">
            Buscar cotización
          </vs-button>
        </div>
        <!-- Provider and quote -->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <template v-if="document.quote">
              <vs-input class="w-full" label="Cotización*" v-model="document.quote.label" disabled/>
            </template>
            <template v-else>
              <vs-input class="w-full" label="Cotización*" value="" disabled/>
            </template>
          </div>
          <div class="vx-col w-1/2">
            <div>
              <label class="text-sm opacity-75">Proveedor*</label>
              <v-select placeholder="Selecciona" @input="getProviderData" :options="providers"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.provider" class="mb-4 md:mb-0" name="provider" id="provider"
                        v-validate="'required'">
                <template #list-header>
                  <li @click="showNewProviderSidebar= true"
                      class="add-new-client-header d-flex align-items-center my-50"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                    />
                    <span class="align-middle ml-25">
                  Nuevo proveedor
                </span>
                  </li>
                </template>
              </v-select>
              <span class="text-danger text-sm"
                    v-show="errors.has('provider')">{{ errors.first('provider') }}</span>
            </div>
          </div>
        </div>

        <!--Address and contact--->
        <div class="vx-row">
          <!-- Address -->
          <div class="mt-5 vx-col w-1/2">
            <label class="text-sm opacity-75">Dirección*</label>
            <v-select :options="addresses"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.address" class="mb-4 md:mb-0" name="address" id="address"
                      v-validate="'required'">
              <template v-if="document.provider" #list-header>
                <li @click="showAddressSidebar= true"
                    class="add-new-client-header d-flex align-items-center my-50"
                >
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                  />
                  <span class="align-middle ml-25">
                  Nueva dirección
                </span>
                </li>
              </template>
            </v-select>
            <span class="text-danger text-sm"
                  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          </div>

          <!-- Contacts -->
          <div class="mt-5 vx-col w-1/2">
            <label class="text-sm opacity-75">Contacto</label>
            <v-select :options="contacts"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.contact" class="mb-4 md:mb-0" name="contact" id="contact"
            >
              <template v-if="document.provider" #list-header>
                <li @click="showContactSidebar= true"
                    class="add-new-client-header d-flex align-items-center my-50"
                >
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                  />
                  <span class="align-middle ml-25">
                  Nuevo contacto
                </span>
                </li>
              </template>
            </v-select>
            <span class="text-danger text-sm"
                  v-show="errors.has('contact')">{{ errors.first('contact') }}</span>
          </div>
        </div>

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!---Currency, Decimals and IGV-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <div>
              <label class="text-sm opacity-75">Moneda*</label>
              <v-select :options="currencies"
                        label="value"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.currency" class="mb-4 md:mb-0" name="currency" id="currency"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('currency')">{{ errors.first('currency') }}</span>
            </div>
          </div>
          <div class="vx-col w-1/2">
            <div>
              <label class="text-sm opacity-75">Tipo de redondeo*</label>
              <v-select @input="calculateTotal" :options="roundingTypes"
                        label="code"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.roundingType" class="mb-4 md:mb-0" name="roundingType" id="roundingType"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('roundingType')">{{ errors.first('roundingType') }}</span>
            </div>
          </div>
        </div>

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Added products-->
        <vs-table class="mt-8" :data="addedProducts">

          <template slot="thead">
            <vs-th>Descripción</vs-th>
            <vs-th>Nro. de Parte</vs-th>
            <vs-th>Precio de cot. ({{ document.quote ? document.quote.currency.symbol : '' }})</vs-th>
            <vs-th>Precio de comp. ({{ document.currency ? document.currency.symbol : '' }})</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Eliminar</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td style="width: 40%" :data="tr.description">
                <vs-textarea disabled rows="5" v-model="tr.description"></vs-textarea>
              </vs-td>

              <vs-td :data="tr.numberPart">
                <p> {{ tr.numberPart }}</p>
              </vs-td>

              <vs-td :data="tr.price">
                <p>
                  {{
                    Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: document.quote.currency.code,
                      minimumFractionDigits: document.quote.roundingType,
                      maximumFractionDigits: document.quote.roundingType,
                    }).format(tr.price)
                  }}
                </p>
              </vs-td>

              <vs-td :data="tr.purchasePrice">
                <template>
                  <vs-input style="width: 80px" min="1" type="number" v-model.number="tr.purchasePrice"/>
                </template>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <vs-input @blur="tr.quantity > tr.max ? tr.quantity = tr.max: '' " style="width: 60px" min="1"
                            :max="tr.max" type="number"
                            v-model.number="tr.quantity"/>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="ml-2" icon="TrashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="deleteProduct(tr)"/>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <!---->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-3/4"></div>
          <div class="vx-col w-1/4">
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Sub total: </span>
              <span>
                {{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: document.currency ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(total / 1.18)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">IGV: </span>
              <span>
                {{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: document.currency ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format((total / 1.18) * 0.18)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Total: </span>
              <span>
                {{
                  Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: document.currency ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(total)
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Generar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import getQuote from '@/mixins/quotes'
import vSelect from 'vue-select'
import NewProvider from '@/views/providers/New'
import NewAddress from '@/views/providers/addresses/New'
import NewContact from '@/views/providers/contacts/New'
import SearchQuotes from '@/views/purchases/SearchQuotes'

import { auth, db, FieldValue, storage } from '@/firebase/firebaseConfig'
import axios from 'axios'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'
import ubigeo from '../../data/ubigeo/ubigeo.json'

const _ = require('lodash')

export default {
  name: 'NewPurchase',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    providers: {
      type: Array,
      required: true
    },
    quotes: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    NewProvider,
    NewAddress,
    NewContact,
    SearchQuotes
  },
  mixins: [trimString, getQuote],
  data () {
    return {
      document: {
        contact: {},
        address: {}
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      addresses: [],
      contacts: [],
      currencies: [
        {
          id: 1,
          value: 'Soles',
          symbol: 'S/',
          code: 'PEN'
        },
        {
          id: 2,
          value: 'Dólares américanos',
          symbol: '$',
          code: 'USD'
        }
      ],
      paymentMethods: [
        {
          id: 30,
          value: 'Crédito a 30 días'
        },
        {
          id: 15,
          value: 'Crédito a 15 días'
        },
        {
          id: 20,
          value: 'Crédito a 20 días'
        },
        {
          id: 45,
          value: 'Crédito a 45 días'
        },
        {
          id: 60,
          value: 'Crédito a 60 días'
        },
        {
          id: 90,
          value: 'Crédito a 90 días'
        },
        {
          days: 0,
          value: 'Contado'
        },
      ],
      roundingTypes: [
        4,
        5,
        6
      ],
      // Sidebars
      showNewProviderSidebar: false,
      showAddressSidebar: false,
      showContactSidebar: false,
      showNewProductSidebar: false,
      showQuotesSidebar: false,
      addedProducts: [],
      selected: null,
      total: 0,
      // Ubigeo
      departments: departments,
      provinces: provinces,
      districts: districts,
      ubigeo: ubigeo
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          currency: {
            id: 2,
            value: 'Dólares américanos',
            symbol: '$',
            code: 'USD'
          },
          roundingType: 4,
          contact: {},
          address: {}
        }
        this.addedProducts = []
        this.$validator.reset()
      }
    },
    addedProducts: {
      deep: true,
      handler () {
        this.calculateTotal()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          if (this.total !== 0) {
            this.progress = true
            // Calculate total
            const total = this.total
            const subTotal = parseFloat((this.total / 1.18).toFixed(this.document.roundingType))
            const igv = parseFloat((total - subTotal).toFixed(this.document.roundingType))
            // Get sequence
            const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequenceForPurchases', {
              headers: {
                'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
              }
            })
            const sequence = response.data.sequence
            // Get exchange rate
            const responseExchange = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/exchangeRate-getExchangeRate', {
              headers: {
                'Authorization': 'Bearer 78b1deb4-1b27-4943-9d83-818285cc59d6'
              }
            })
            // End
            let obj = {
              ...this.document,
              state: true,
              status: 'hold',
              products: [
                ...this.addedProducts
              ],
              total,
              subTotal,
              igv,
              user: this.document.quote.user || null,
              code: 'OC-' + sequence,
              exchangeRate: responseExchange.data.venta + 0.025
            }
            // Filter Products
            obj.filterProducts = []
            obj.products.forEach((p) => {
              obj.filterProducts.push(p.numberPart)
            })
            // End
            // Save in firestore
            const doc = await db.collection('purchases').add({
              ...obj,
              createdAt: FieldValue.serverTimestamp()
            })
            // Add purchases products to quote
            this.document.quote.products.forEach((qp) => {
              if (qp.type === 'Regular') {
                const product = this.addedProducts.find((pp) => pp.id === qp.id && pp.description.trim() === qp.description.trim())
                if (product) {
                  qp.purchaseOrder += product.quantity
                }
              }
              else { // Composite
                qp.addedProducts.forEach((cp) => {
                  const product = this.addedProducts.find((pp) => pp.id === cp.id)
                  if (product) {
                    cp.purchaseOrder += product.quantity
                  }
                })
                qp.purchaseOrder = qp.quantity
              }
            })
            // End
            // Update Quote status and products
            await db.collection('quotes').doc(this.document.quote.id).update({
              status: 'inProcess',
              products: this.document.quote.products
            })
            obj.id = doc.id
            obj.createdAt = new Date()
            const responsePdf = await this.generatePDF(obj)
            setTimeout(async () => {
              const link = await this.getPdfUrl(responsePdf.id, obj.id)
              await db.collection('purchases').doc(obj.id).update({
                pdfId: responsePdf.id,
                link
              })
              obj.link = link
              this.$emit('closeSidebar')
              this.$emit('add', obj)
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Orden de compra',
                text: 'Orden de compra creada correctamente.'
              })
            }, 7000)
          }
          else {
            this.$vs.notify({
              color: 'warning',
              title: 'Orden de compra',
              text: 'Revisa cantidad y precio de productos.'
            })
          }
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      }
    },
    /**
     * Filter address by provider
     */
    async filterAddressByProvider () {
      try {
        if (this.document.provider.id) {
          this.addresses = []
          this.addresses.push({
            address: this.document.provider.address,
            department: this.document.provider.department,
            province: this.document.provider.province,
            district: this.document.provider.district,
            ubigeo: this.document.provider.ubigeo,
            label: this.document.provider.address + ' - ' + this.document.provider.department + ' - ' + this.document.provider.province + ' - ' + this.document.provider.district
          })
          this.progress = true
          this.list = []
          const querySnap = await db.collection('providers').doc(this.document.provider.id)
              .collection('addresses')
              .orderBy('createdAt', 'desc').get()
          querySnap.forEach((doc) => {
            let obj = {
              id: doc.id,
              address: doc.data().address,
              department: doc.data().department,
              province: doc.data().province,
              district: doc.data().district
            }
            obj.label = obj.address + ' - ' + obj.department.nomDepartamento + ' - ' + obj.province.nomProvincia + ' - ' + obj.district.nomDistrito
            this.addresses.push(obj)
          })
          this.document.address = this.addresses[0]
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Filter contact by provider
     */
    async filterContactByProvider () {
      try {
        if (this.document.provider.id) {
          this.contacts = []
          this.progress = true
          this.list = []
          const querySnap = await db.collection('providers').doc(this.document.provider.id)
              .collection('contacts')
              .orderBy('createdAt', 'desc').get()
          querySnap.forEach((doc) => {
            let obj = {
              id: doc.id,
              name: doc.data().name,
              lastName: doc.data().lastName
            }
            obj.label = obj.name + ' ' + obj.lastName
            this.contacts.push(obj)
          })
          this.progress = false
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Get client data
     * @returns {Promise<void>}
     */
    getProviderData () {
      try {
        this.document.address = null
        this.document.contact = null
        this.filterContactByProvider()
        this.filterAddressByProvider()
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Client Sidebar
     */
    addProviderSidebar (o) {
      this.providers.unshift({
        id: o.id,
        document: o.document,
        typeDocument: o.typeDocument,
        businessName: o.businessName,
        label: o.businessName + ' - ' + o.document,
        address: o.address,
        department: o.department,
        province: o.province,
        district: o.district,
        ubigeo: o.ubigeo
      })
    },
    /**
     * Address sidebar
     * @param o
     */
    addAddressSidebar (o) {
      let obj = {
        id: o.id,
        address: o.address,
        department: o.department,
        province: o.province,
        district: o.district
      }
      obj.label = obj.address + ' - ' + obj.department.nomDepartamento + ' - ' + obj.province.nomProvincia + ' - ' + obj.district.nomDistrito
      this.addresses.unshift(obj)
    },
    /**
     * Contact sidebar
     * @param o
     */
    addContactSidebar (o) {
      let obj = {
        id: o.id,
        name: o.name,
        lastName: o.lastName
      }
      obj.label = obj.name + ' ' + obj.lastName
      this.contacts.push(obj)
    },
    /**
     * Search quote sidebar
     */
    async addQuoteSidebar (o) {
      try {
        this.showQuotesSidebar = false
        this.progress = true
        this.addedProducts = []
        const quote = await this.getQuote(o.id)
        this.document.quote = _.cloneDeep(quote)
        this.document.quote.label = this.document.quote.code + ' - ' + this.document.quote.client.businessName
        this.document.quote.products.forEach((p) => {
          if (p.type === 'Regular') {
            const obj = {
              id: p.id,
              sku: p.sku,
              numberPart: p.numberPart,
              price: p.price,
              purchasePrice: 0,
              quantity: p.quantity - p.purchaseOrder,
              max: p.quantity - p.purchaseOrder,
              description: p.description
            }
            if (obj.quantity > 0) {
              this.addedProducts.push(obj)
            }
          }
          else {
            p.addedProducts.forEach((p2) => {
              const obj = {
                id: p2.id,
                sku: p2.sku,
                numberPart: p2.numberPart,
                price: 0,
                purchasePrice: 0,
                quantity: p2.finalQuantity - p2.purchaseOrder,
                max: p2.finalQuantity - p2.purchaseOrder,
                description: p2.description,
                composite: true,
                father: p.id
              }
              if (obj.quantity > 0) {
                this.addedProducts.push(obj)
              }
            })
          }
        })
        if (this.addedProducts.length === 0) {
          this.$vs.notify({
            color: 'warning',
            title: 'Atención',
            text: 'Cotización no tiene productos pendientes de compra.'
          })
          this.document.quote = null
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Delete product
     */
    deleteProduct (product) {
      if (this.addedProducts.length !== 1) {
        const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
        this.addedProducts.splice(indexProduct, 1)
      }
    },
    /**
     * Calculate total
     */
    calculateTotal () {
      this.total = 0
      this.addedProducts.forEach((p) => {
        this.total += parseFloat((p.quantity * p.purchasePrice * 1.18).toFixed(this.document.roundingType))
      })
      this.total = parseFloat(this.total.toFixed(2))
    },
    /**
     * Generate PDF
     */
    async generatePDF (purchase) {
      const products = []
      this.addedProducts.forEach((p) => {
        const obj = {
          description: p.description.substr(0, 62),
          numberPart: p.numberPart,
          quantity: p.quantity
        }

        obj.price = parseFloat((p.purchasePrice).toFixed(purchase.roundingType))
        obj.total = parseFloat((p.quantity * p.purchasePrice).toFixed(purchase.roundingType))

        obj.price = purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
          currency: purchase.currency.code,
          minimumFractionDigits: purchase.roundingType,
          maximumFractionDigits: purchase.roundingType,
        }).format(obj.price)

        obj.total = purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
          currency: purchase.currency.code,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(obj.total)

        products.push(obj)
      })
      const response = await this.$http.post('https://api.pdfmonkey.io/api/v1/documents', {
        document: {
          // document_template_id: 'A5F0EE40-8869-4C3A-A99C-DA52F7C17BD4',
          document_template_id: 'C3E83873-47C0-4542-87F5-85D027E8C2CF',
          payload: {
            'code': purchase.code,
            'createdAt': `Lima ${new Date().getDate()} de ${this.getMonth(new Date().getMonth())} del ${new Date().getFullYear()}`,
            'providerName': purchase.provider.businessName,
            'providerDocument': purchase.provider.document,
            'providerAddress': purchase.address.address + ', ' + purchase.address.district + ', ' + purchase.address.province + ', ' + purchase.address.department,
            'userName': auth.currentUser.displayName,
            'userPhone': auth.currentUser.phoneNumber,
            'userEmail': auth.currentUser.email,
            'subTotal': purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: this.document.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(purchase.subTotal),
            'igv': purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: this.document.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(purchase.igv),
            'total': purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: this.document.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(purchase.total),
            products
          },
          status: 'pending'
        }
      }, {
        headers: {
          //'Authorization': 'Bearer iVtju3Mvy6c2gzhGWerf'
          'Authorization': 'Bearer xmxWbNo2oEWMAdaHxkQi'
        }
      })
      return response.data.document
    },
    /**
     * Get URL of monkey
     */
    async getPdfUrl (id, docId) {
      let response = null
      try {
        response = await this.$http.get(`https://api.pdfmonkey.io/api/v1/documents/${id}`, {
          headers: {
            // 'Authorization': 'Bearer iVtju3Mvy6c2gzhGWerf'
            'Authorization': 'Bearer xmxWbNo2oEWMAdaHxkQi'
          }
        })
        // Save in firebase storage
        const timestamp = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + '-' + new Date().getHours() + '-' + new Date().getMinutes() + '-' + new Date().getSeconds()
        const docNameFinal = docId.toUpperCase() + '-' + timestamp + '.pdf'
        const responsePdf = await window.fetch(response.data.document.download_url)
        const storageRef = storage.ref(`purchases/${docNameFinal}`)
        const blob = await responsePdf.blob()
        const snapshot = await storageRef.put(blob)
        const url = await snapshot.ref.getDownloadURL()
        return url
      } catch (e) {
        return response.data.document.download_url
      }
    },
    /**
     * Get mont in string
     * @param month
     * @returns {string}
     */
    getMonth (month) {
      switch (month) {
        case 0:
          return 'Enero'
        case 1:
          return 'Febrero'
        case 2:
          return 'Marzo'
        case 3:
          return 'Abril'
        case 4:
          return 'Mayo'
        case 5:
          return 'Junio'
        case 6:
          return 'Julio'
        case 7:
          return 'Agosto'
        case 8:
          return 'Septiembre'
        case 9:
          return 'Octubre'
        case 10:
          return 'Noviembre'
        case 11:
          return 'Diciembre'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.add-new-client-header {
  padding: 8px;
  color: green;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 15px;
    margin-right: 4px;
  }

  &:hover {
    background-color: rgba(green, 0.12);
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
